import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/components/main/Main.vue'
//import Knowledge1 from '@/components/theme/Knowledge.vue'

import Calendar from '@/components/theme/Calendar.vue'

//import Channel from '@/components/theme/News.vue'

import News from '@/components/theme/News_2.vue'

import Knowledge from '@/components/theme/Knowledge_2.vue'

import Gantt from '@/components/theme/Gantt.vue'

//import MedicationList from '@/components/medication-list/medication-list.vue'

//import Master from '@/components/master/Master.vue'

import LoginPage from '@/components/login/login-box.vue'
import SignupPage from '@/components/login/signup-box.vue'

const routes = [
  {
    path: '/',
    redirect: '/news/all',
  },
  /*
  {
    path: '/knowledge',
    name: 'Knowledge1',
    component: Knowledge1
  },
*/
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },

/*
  {
    path: '/channel',
    name: 'Channel',
    component: Channel
  },
*/
  {
    path: '/news/all',
    name: 'News',
    component: News
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News
  },

/*  
  {
    path: '/knowledge/all',
    name: 'Knowledge',
    component: Knowledge
  },
*/
  {
    path: '/knowledge/:id',
    name: 'Knowledge',
    hash: ':hash',
    component: Knowledge
  },
 

/*
  {
    path: '/medicationlist',
    name: 'MedicationList',
    component: MedicationList
  },

  {
    path: '/master',
    name: 'Master',
    component: Master
  },
*/

  {
    path: '/gantt',
    name: 'Gantt',
    component: Gantt
  },

  {
    path: '/about',
    name: 'Main',
    component: Main
  },


  { 
    path: '/login',
    name: 'LoginPage', 
    component: LoginPage 
  },
  { 
    path: '/signup',
    name: 'SignupPage', 
    component: SignupPage 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',

  scrollBehavior (to, from, savedPosition) {

    if (to.hash) {
      return {
        el: to.hash,
        top: 32,
      } 
    }

    if (savedPosition) {
      return savedPosition
    } else {
    //  return false
      //return { top: window.innerHeight / 6 }
      return { top: 0 }
    }
  }
})

export default router
