<template>

  <div class="kn-nav">

    <div v-if="isHideControl()" class="kn-button-page-control" @click="toggleHideCotrol()" title="Show filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>
    <div v-else class="kn-button-page-control" @click="toggleHideCotrol()" title="Hide filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>

    <div class="kn-options__value-contaier">
      <span><input v-model="classes.get('countries').view" type="checkbox" name="countries" @change="changeViewCountries()"/></span>
      <label class="checkbox-label" for="countries">Countries</label>
    </div>

  </div>

  <div class="kn-page-main__container" v-if="renderComponent">
    <div class="kn-knowledge-control" v-if="!isHideControl() && renderComponent">

    <div  class="kn-knowledge-control__classes">
      <button class="hb-button" @click="preFilterKnowledgeItems">
        Apply 
      </button>
    </div>

    <div class="kn-knowledge-control__class-long" v-if="classView('classHibiInfoItems')">
      <Class :editMode="false" :classOptions="classesOptions.get('classHibiInfoItems').knowledge_classOptions"/>
    </div>

    <div class="kn-knowledge-control__class" v-if="classView('countries')">
      <Class :editMode="false" :classOptions="classesOptions.get('countries').knowledge_classOptions"/>
    </div>

    </div>

    <div ref="scrollContainer" v-if="!initialLoad">
      <div class="kn-page-article__wrapper"  :style="{ height: 'auto' }">
        <KnowledgeItem v-for="(channel,index) in list"
          :key="index"
          :item="channel"
        />
      </div>

      <div class="sentinel" ref="sentinel"></div>
      <div v-if="canLoadMore" >More .....</div>
    </div>
    <div v-else>Initial load .....</div>

  </div>
  
</template>

<script>
import { mapState } from 'vuex'
import Class from '@/components/classes/class.vue'
import KnowledgeItem from '@/components/knowledge/KnowledgeListViewItem.vue';

export default {
  name: 'KnowledgeList',
  components: { 
    Class,
    KnowledgeItem, 
  },
  data () {
    const classesMap = new Map();

    return {
      renderComponent: true,

      viewMode: 0,  //0 - List, 1 - Item
      newKnowledge: this.knowledgeId === 0,
      editKnowledge: this.knowledgeId > 0,
      classes: classesMap,
      themeOptions: {},

      filter: {},
      list: [],

      lastItem: -1, // index of the last loaded item
      bufferLength: 12,
      isLoadingMore: false,
      canLoadMore: false, // store.channelItems.channel.length > 0, // list has to end at some point

      // extra stuff
      initialLoad: true // initial load state ,if you want  to use a different BIGGER loading animation  etc
      
    }
  },

  async created () {
    try {

      this.classes.set('countries', this.classesOptions.get('countries').knowledge_classOptions)
      this.classes.set('classHibiInfoItems', this.classesOptions.get('classHibiInfoItems').knowledge_classOptions)

      this.themeOptions = this.themesOptions.get('knowledge').knowledge_themeOptions

      this.filter = this.themeOptions.filter

      this.setFilter()

      this.preFilterKnowledgeItems()      

      //
    } catch (error) {
      this.canLoadMore = false
      console.error('Knowledge Page created error:', error)
    } finally {
      this.isLoadingMore = false
    }
  },

  unmounted () {
    if (this.listEndObserver) {
      this.listEndObserver.disconnect()
    }
  },
  
  computed: {
    ...mapState({
      classesOptions: state => state.classes2,
      themesOptions: state => state.themes2
    }),

  },

  methods: {
    newItem () {
      this.$router.push('/knowledge/new')
    },

    isHideControl() {
      if (this.themeOptions.view == false) {
        return true
      } else {
        return false 
      }
    },
    toggleHideCotrol () {
      this.themeOptions.view = !this.themeOptions.view
    },

    changeViewClassHibiInfoItems() {
      //if (!this.classes.get('classHibiInfoItems').view) {
        this.handleFilter(this.classes.get('classHibiInfoItems'))
      //}
    },
    changeViewCountries() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('countries'))
      //}
    },
    classView (className) {
      return this.classes.get(className).view
    },

    setFilter () {

      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }
      
    },

    handleFilter (cls) {
      
      switch (cls.className) {

        case 'countries':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.locality = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.locality = this.filter.locality + cls.filter.vl[i] + ','
                }
                this.filter.locality = this.filter.locality.substring(0, this.filter.locality.length-1)
              } else {
                this.filter.locality = cls.filter.vl.toString()
              }
              this.filter.locality = '{' + this.filter.locality + '}'
            } else {
              this.filter.locality = '{}'  
            }
          
          } else {
            this.filter.locality = '{0}'
          }

          //console.log('this.filter.locality: ', this.filter.locality)
        
        break;

        case 'classHibiInfoItems':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.clsfrs = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.clsfrs = this.filter.clsfrs + cls.filter.vl[i] + ','
                }
                this.filter.clsfrs = this.filter.clsfrs.substring(0, this.filter.clsfrs.length-1)
              } else {
                this.filter.clsfrs = cls.filter.vl.toString()
              }
              this.filter.clsfrs = '{' + this.filter.clsfrs + '}'
            } else {
              this.filter.clsfrs = '{}'  
            }
          
          } else {
            this.filter.clsfrs = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;
      }

      //console.log('handleFilter this.filter, cls: ', this.filter, cls)
    },

    preFilterKnowledgeItems () {
      if (this.filter.event !== '{}' && this.filter.locality !== '{}' && this.filter.clsfrs !== '{}') {
        this.filterKnowledgeItems ()
      } else {
        window.prompt('When a classifier is selected, at least one item must be selected. \n Please select a classifier item or deselect a classifier. ');
      }
    },

    async filterKnowledgeItems () {
      try {

        //console.log('this.themeOptions: ', this.themeOptions)

        this.setFilter();

        await this.$store.dispatch('getThemeItems', this.themeOptions)

        this.list = []

        this.canLoadMore = this.themesOptions.get('knowledge').data.length > 0

        //console.log('this.canLoadMore: ', this.canLoadMore, this.themesOptions.get('knowledge').data)

        if (this.canLoadMore) {
          this.lastItem = -1;
          this.fetchItemsAPI().then((items) => {
            this.list.push(...items)
            this.initialLoad = false

            // wait for initial list to render and then set up observer
            this.$nextTick().then(() => {
              this.setUpInterSectionObserver()
            })
          })
        }
        //console.log('this.list: ', this.list)
          
      } catch (error) {
        this.canLoadMore = false
        console.error('filterKnowledgeItems error:', error)
      } finally {
        this.isLoadingMore = false
      }
    },

    setUpInterSectionObserver () {
      const options = {
        root: null, //this.$refs.scrollContainer,
        margin: '10px'
      }
      this.listEndObserver = new IntersectionObserver(
        this.handleIntersection,
        options
      )

      this.listEndObserver.observe(this.$refs.sentinel)
    },

    handleIntersection ([entry]) {
      // if (entry.isIntersecting) {
      //   console.log('sentinel intersecting')
      // }
      // console.log('handleIntersection')
      if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
        this.loadMore()
      }
    },

    async loadMore () {
      try {
        this.isLoadingMore = true
        const items = await this.fetchItemsAPI()
        // console.log('loaded next buffer included item ' + this.lastItem)
        this.list.push(...items) // add items in the front
      } catch (error) {
        // console.log('Reached end of page', error)
        this.canLoadMore = false
      } finally {
        this.isLoadingMore = false
      }
    },

    fetchItemsAPI () {
      return new Promise((resolve, reject) => {
        const items = []
        const data = this.themesOptions.get('knowledge').data

        let k = 0
        if (this.lastItem < data.length - 1) {
          for (k = this.lastItem + 1; (k < this.lastItem + 1 + this.bufferLength) && (k < data.length); k++) {
            items.push(data[k])
          }
          this.lastItem = k - 1

          // console.log(k, items)
        } else {
          this.lastItem = this.lastItem + 1
        }

        setTimeout(() => {
          if (this.lastItem <= data.length) {
            resolve(items)
            if (this.lastItem == data.length) {
              this.canLoadMore = false
            }
          } else {
            reject(new Error('No more items to load'))
          }
        }, 100)
      })
    },

    forceRerender() {
      //Remove the component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        //Add the component back in
        this.renderComponent = true
      })
    },

  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/knowledge_2.scss';
</style>
