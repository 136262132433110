<template>

  <div class="nw-nav">

    <div v-if="isHideControl()" class="nw-button-page-control" @click="toggleHideControl()" title="Show filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>
    <div v-else class="nw-button-page-control" @click="toggleHideControl()" title="Hide filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>


    <div class="nw-options__value-contaier">
      <span><input v-model="classes.get('classNewsThemes').view" type="checkbox" name="countries" @change="changeViewNewsThemes()"/></span>
      <label class="checkbox-label" for="countries">Themes</label>
    </div>

    <div class="nw-options__value-contaier">
      <span><input v-model="classes.get('classNewsSources').view" type="checkbox" name="countries" @change="changeViewNewsSources()"/></span>
      <label class="checkbox-label" for="countries">Sources</label>
    </div>

    <div class="nw-options__value-contaier">
      <span><input v-model="classes.get('countries').view" type="checkbox" name="countries" @change="changeViewCountries()"/></span>
      <label class="checkbox-label" for="countries">Countries</label>
    </div>

    <div class="nw-options__value-contaier">
      <span><input v-model="classes.get('classHibiInfoItems').view" type="checkbox" name="countries" @change="changeViewClassHibiInfoItems()"/></span>
      <label class="checkbox-label" for="countries">Info</label>
    </div>

  </div>

  <div class="nw-page-main__container" v-if="renderComponent">
    <div class="nw-news-control" v-if="!isHideControl()">

      <div  class="nw-news-control__class-date">
        <button class="hb-button" @click="preFilterNewsItems">
          Apply 
        </button>

        <div class="nw-news-control__button-wrapper">
          <button class="hb-button-short" @click="prevWeek">
            &lt;&lt;
          </button>
        </div>
        <div class="nw-news-control__date-wrapper">
          <input type="date" placeholder="Set Date" v-model="eventDate" required>
        </div>
      </div>

      <div class="nw-news-control__class" v-if="classView('classNewsThemes')">
        <Class :editMode="false" :classOptions="classesOptions.get('classNewsThemes').news_classOptions"/>
      </div>

      <div class="nw-news-control__class" v-if="classView('classNewsSources')">
        <Class :editMode="false" :classOptions="classesOptions.get('classNewsSources').news_classOptions"/>
      </div>

      <div class="nw-news-control__class" v-if="classView('countries')">
        <Class :editMode="false" :classOptions="classesOptions.get('countries').news_classOptions"/>
      </div>

      <div class="nw-news-control__class-long" v-if="classView('classHibiInfoItems')">
        <Class :editMode="false" :classOptions="classesOptions.get('classHibiInfoItems').news_classOptions"/>
      </div>

    </div>

    <div ref="scrollContainer" v-if="!initialLoad">
      <div class="nw-page-article__wrapper"  :style="{ height: 'auto' }">
        <NewsItem v-for="(channel,index) in list"
          :key="index"
          :item="channel"
        />
      </div>

      <div class="sentinel" ref="sentinel"></div>
      <div v-if="canLoadMore" >More .....</div>
    </div>
    <div v-else>Initial load .....</div>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import Class from '@/components/classes/class.vue';
import NewsItem from '@/components/news/NewsListViewItem.vue';
import moment from 'moment';

export default {
  name: 'NewsList',
  data () {
    const classesMap = new Map();

    return {
      renderComponent: true,

      viewMode: 0,  //0 - List, 1 - Item
      newNews: this.newsId === 0,
      editNews: this.newsId > 0,
      classes: classesMap,
      themeOptions: {},

      eventDate: '',

      filter: {},
      list: [],

      lastItem: -1, // index of the last loaded item
      bufferLength: 12,
      isLoadingMore: false,
      canLoadMore: false, // store.channelItems.channel.length > 0, // list has to end at some point

      // extra stuff
      initialLoad: true // initial load state ,if you want  to use a different BIGGER loading animation  etc

    }
  },
  
  components: {
    Class,
    NewsItem,
  },
  
  async created () {
    try {

      //await this.$store.getters.interfaces != 0;

      this.classes.set('time', this.classesOptions.get('time').news_classOptions)
      this.classes.set('classNewsThemes', this.classesOptions.get('classNewsThemes').news_classOptions)
      this.classes.set('classNewsSources', this.classesOptions.get('classNewsSources').news_classOptions)
      this.classes.set('countries', this.classesOptions.get('countries').news_classOptions)
      this.classes.set('classHibiInfoItems', this.classesOptions.get('classHibiInfoItems').news_classOptions)

      this.themeOptions = this.themesOptions.get('news').news_themeOptions

      this.filter = this.themeOptions.filter
      this.eventDate = this.filter.time.vl.substring(0, 10)

      this.setFilter()

      this.preFilterNewsItems()      


    } catch (error) {
      this.canLoadMore = false
      console.error('News Page created error:', error)
    } finally {
        this.isLoadingMore = false
    } 
  },

  unmounted () {
    if (this.listEndObserver) {
      this.listEndObserver.disconnect()
    }
  },

  computed: {
    ...mapState({
      classesOptions: state => state.classes2,
      themesOptions: state => state.themes2
    })

  },

  methods: {
    newItem () {
      this.$router.push('/news/new')
    },

    isHideControl() {
      if (!this.themeOptions.view) {
        return true
      } else {
        return false 
      }
    },
    toggleHideControl() {
      this.themeOptions.view = !this.themeOptions.view
    },

    changeViewNewsThemes() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('classNewsThemes'))
      //}
    },
    changeViewNewsSources() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('classNewsSources'))
      //}
    },
    changeViewClassHibiInfoItems() {
      //if (!this.classes.get('classHibiInfoItems').view) {
        this.handleFilter(this.classes.get('classHibiInfoItems'))
      //}
    },
    changeViewCountries() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('countries'))
      //}
    },

    classView(className) {
      return this.classes.get(className).view
    },

    setFilter () {

      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }
      
    },

    handleFilter (cls) {
      
      switch (cls.className) {

        case 'classNewsThemes':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.thm = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.thm = this.filter.thm + cls.filter.vl[i] + ','
                }
                this.filter.thm = this.filter.thm.substring(0, this.filter.thm.length-1)
              } else {
                this.filter.thm = cls.filter.vl.toString()
              }
              this.filter.thm = '{' + this.filter.thm + '}'
            } else {
              this.filter.thm = '{}'  
            }
          
          } else {
            this.filter.thm = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;

        case 'classNewsSources':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.src = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.src = this.filter.src + cls.filter.vl[i] + ','
                }
                this.filter.src = this.filter.src.substring(0, this.filter.src.length-1)
              } else {
                this.filter.src = cls.filter.vl.toString()
              }
              this.filter.src = '{' + this.filter.src + '}'
            } else {
              this.filter.src = '{}'  
            }
          
          } else {
            this.filter.src = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;

        case 'countries':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.locality = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.locality = this.filter.locality + cls.filter.vl[i] + ','
                }
                this.filter.locality = this.filter.locality.substring(0, this.filter.locality.length-1)
              } else {
                this.filter.locality = cls.filter.vl.toString()
              }
              this.filter.locality = '{' + this.filter.locality + '}'
            } else {
              this.filter.locality = '{}'  
            }
          
          } else {
            this.filter.locality = '{0}'
          }

          //console.log('this.filter.locality: ', this.filter.locality)
        
        break;

        case 'classHibiInfoItems':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.clsfrs = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.clsfrs = this.filter.clsfrs + cls.filter.vl[i] + ','
                }
                this.filter.clsfrs = this.filter.clsfrs.substring(0, this.filter.clsfrs.length-1)
              } else {
                this.filter.clsfrs = cls.filter.vl.toString()
              }
              this.filter.clsfrs = '{' + this.filter.clsfrs + '}'
            } else {
              this.filter.clsfrs = '{}'  
            }
          
          } else {
            this.filter.clsfrs = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;
      }

      //console.log('handleFilter this.filter, cls: ', this.filter, cls)
    },

    prevWeek () {
      const d = new Date(this.eventDate)
      d.setDate(d.getDate() - 7)
      this.eventDate = d.toISOString().substring(0, 10)
      this.preFilterNewsItems()
    },

    preFilterNewsItems () {
      if (this.filter.event !== '{}' && this.filter.locality !== '{}' && this.filter.clsfrs !== '{}') {
        this.filterNewsItems ()
      } else {
        window.prompt('When a classifier is selected, at least one item must be selected. \n Please select a classifier item or deselect a classifier. ');
      }
    },

    async filterNewsItems () {
      try {

        if (this.listEndObserver) {
          this.listEndObserver.disconnect()
        }

        
        this.filter.time.vl = moment(this.eventDate).format()

        this.setFilter();
        
//        this.filter.clsfrs = '{0,2}'
      
        await this.$store.dispatch('getThemeItems', this.themeOptions)

        this.list = []
        //console.log('this.$store.getters.channelItems: ', this.$store.getters.channelItems, this.list)
        this.canLoadMore = this.themesOptions.get('news').data.length > 0

        if (this.canLoadMore) {
          this.lastItem = -1;
          this.fetchItemsAPI().then((items) => {
            this.list.push(...items)
            this.initialLoad = false

            // wait for initial list to render and then set up observer
            this.$nextTick().then(() => {
              this.setUpInterSectionObserver()
            })
          })
        }

        //this.list = this.themesOptions.get('news').data
        //console.log('this.list: ', this.list)
        //this.forceRerender()
          
      } catch (error) {
        this.canLoadMore = false
        console.error('filterNewsItems error:', error)
      } finally {
        this.isLoadingMore = false
      }
    },

    setUpInterSectionObserver () {
      const options = {
        root: null, //this.$refs.scrollContainer,
        margin: '10px'
      }
      this.listEndObserver = new IntersectionObserver(
        this.handleIntersection,
        options
      )

      this.listEndObserver.observe(this.$refs.sentinel)
    },

    handleIntersection ([entry]) {
      // if (entry.isIntersecting) {
      //   console.log('sentinel intersecting')
      // }
      // console.log('handleIntersection')
      if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
        this.loadMore()
      }
    },

    async loadMore () {
      try {
        this.isLoadingMore = true
        const items = await this.fetchItemsAPI()
        // console.log('loaded next buffer included item ' + this.lastItem)
        this.list.push(...items) // add items in the front
      } catch (error) {
        // console.log('Reached end of page', error)
        this.canLoadMore = false
      } finally {
        this.isLoadingMore = false
      }
    },

    fetchItemsAPI () {
      return new Promise((resolve, reject) => {
        const items = []
        const data = this.themesOptions.get('news').data

        let k = 0
        if (this.lastItem < data.length - 1) {
          for (k = this.lastItem + 1; (k < this.lastItem + 1 + this.bufferLength) && (k < data.length); k++) {
            items.push(data[k])
          }
          this.lastItem = k - 1

          // console.log(k, items)
        } else {
          this.lastItem = this.lastItem + 1
        }

        setTimeout(() => {
          if (this.lastItem <= data.length) {
            resolve(items)
            if (this.lastItem == data.length) {
              this.canLoadMore = false
            }
          } else {
            reject(new Error('No more items to load'))
          }
        }, 100)
      })
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/news_2.scss';
</style>
